
// Cart
var sistemas = $('div[data-page="cart"]');
if (sistemas.length>0){
    jQuery('input[name=\'postcode\']').keydown(function(event) {
        if (event.keyCode == 13) {
            event.preventDefault();
            jQuery('#button-quote').trigger('click');
            return false;
        }
    });
    $('#button-quote').on('click', function() {
        $.ajax({
            url: 'index.php?route=extension/total/shipping/quote',
            type: 'post',
            data: 'country_id=' + $('select[name=\'country_id\']').val() + '&zone_id=' + $('select[name=\'zone_id\']').val() + '&postcode=' + encodeURIComponent($('input[name=\'postcode\']').val()),
            dataType: 'json',
            beforeSend: function() {
                $('#button-quote').attr('disabled', true).text('Aguarde...');
            },
            complete: function() {
                $('#button-quote').attr('disabled', false).text('OK');
            },
            success: function(json) {
                //$('.alert, .text-danger').remove();

                if (json['error']) {
                    if (json['error']['warning']) {
                        notification.error(json['error']['warning']);
                    }

                    if (json['error']['country']) {
                        $('#country').removeClass('hidden');
                        $('select[name=\'country_id\']').after('<div class="text-danger">' + json['error']['country'] + '</div>');
                    }

                    if (json['error']['zone']) {
                        $('#zone').removeClass('hidden');
                        $('select[name=\'zone_id\']').after('<div class="text-danger">' + json['error']['zone'] + '</div>');
                    }

                    if (json['error']['postcode']) {
                        $('input[name=\'postcode\']').parent().after('<div class="text-danger">' + json['error']['postcode'] + '</div>');
                    }
                }

                if (json['shipping_method']) {
                    $('#loadFrete').html('');

                    htmlFrete = '<div class="col__carrinho__simulacao frete__col">';
                    htmlFrete += '<div class="carrinho__simulacao__desc">Escolha a forma de envio</div>';

                        for (i in json['shipping_method']) {
                            htmlFrete += '<strong>' + json['shipping_method'][i]['title'] + '</strong>';
                            if (!json['shipping_method'][i]['error']) {
                                for (j in json['shipping_method'][i]['quote']) {
                                    htmlFrete += '<div class="checkbox__custom contato__check">';
                                    htmlFrete+='<input id="ss'+ json['shipping_method'][i]['quote'][j]['code'] +'" class="custom__check__input" type="radio" name="shipping_method" value="' + json['shipping_method'][i]['quote'][j]['code'] + '" />';
                                    htmlFrete += '<label class="custom__check__label" for="ss'+ json['shipping_method'][i]['quote'][j]['code'] +'">';
                                    htmlFrete += json['shipping_method'][i]['quote'][j]['title'] + ' - ' + json['shipping_method'][i]['quote'][j]['text'];
                                    htmlFrete+='</label>';
                                    htmlFrete += '</div>';
                                }
                            } else {
                                htmlFrete += '<div class="alert alert-danger">' + json['shipping_method'][i]['error'] + '</div>';
                            }
                        }
                    htmlFrete += '</div>';
                    htmlFrete += '<input style="display: none;" type="button" value="Utilizar frete" id="button-shipping" data-loading-text="Carregando..." class="btn btn-primary" />';
                    $('#loadFrete').html(htmlFrete);

                    $('input[name=\'shipping_method\']').on('change', function() {
                        $('#button-shipping').prop('disabled', false).trigger('click');
                    });
                }
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                window.alert("Parece que estamos com uma instabilidade, por favor tente mais tarde.");
            }
        });
    });

    $(document).delegate('#button-shipping', 'click', function() {
        $.ajax({
            url: 'index.php?route=extension/total/shipping/shipping',
            type: 'post',
            data: 'shipping_method=' + encodeURIComponent($('input[name=\'shipping_method\']:checked').val()),
            dataType: 'json',
            beforeSend: function() {
                $('#button-shipping').button('loading');
            },
            complete: function() {
                $('#button-shipping').button('reset');
            },
            success: function(json) {
                $('.alert').remove();

                if (json['error']) {
                    notification.error(json['error']);
                }

                if (json['redirect']) {
                    location = json['redirect'];
                }
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                window.alert("Parece que estamos com uma instabilidade, por favor tente mais tarde.");
            }
        });
    });

    $('#button-coupon').on('click', function() {
        $.ajax({
            url: 'index.php?route=extension/total/coupon/coupon',
            type: 'post',
            data: 'coupon=' + encodeURIComponent($('input[name=\'coupon\']').val()),
            dataType: 'json',
            beforeSend: function() {
                $('#button-coupon').button('loading');
            },
            complete: function() {
                $('#button-coupon').button('reset');
            },
            success: function(json) {
                $('.alert').remove();

                if (json['error']) {
                    //$('#notification').html('<div class="alert alerta-danger"><i class="fa fa-exclamation-circle"></i> ' + json['error'] + '<button type="button" class="close" data-dismiss="alert">&times;</button></div>');
                    //$('html, body').animate({ scrollTop: 0 }, 'slow');

                    Swal.fire("Ops..", "" + json["error"] + "", "error");

                }

                if (json['redirect']) {
                    location = json['redirect'];
                }
            }
        });
    });
}